import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { NgSelectConfig } from '@ng-select/ng-select';

declare function require(moduleName: string): any;
const { version: appVersion } = require('../../../../package.json');

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
})
export class AppComponent implements OnInit {
  public appVersion: any;
  public date?: Date;

  constructor(
    private title: Title,
    private config: NgSelectConfig
  ) {
    this.title.setTitle('Eneza Web');
    this.config.appendTo = 'body';
  }

  ngOnInit() {
    this.date = new Date();
    this.appVersion = appVersion;
  }
}
